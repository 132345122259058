import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../Assets/logo.png'
import '../Assets/Styles/WelcomePage.css'

const WelcomePage = () => {

  const navigate = useNavigate()

  const toSignup = (() => {
    navigate('/signup')
  })

  const toLogin = (() => {
    navigate('/login')
  })

  return (
    <div className='welcome-main'>
        <img src={logo} alt='Retexter' className='welcome-img' />
        <h4 className='welcome-text1'>welcome to retexter</h4>
        <h3 className='welcome-text2'>log in or sign up to continue</h3>
        <div className='welcome-btns'>
            <button className='welcome-btn' onClick={toLogin}>Login</button>
            <button className='welcome-btn' onClick={toSignup}>Sign up</button>
        </div>
    </div>
  )
}

export default WelcomePage