import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../Assets/Styles/Layout.css'
import star from '../Assets/Icons/star.png'
import credit from '../Assets/Icons/credit.png'
import Pharapraser from '../Pages/Pharapraser';
import PlagCheck from '../Pages/PlagCheck';
import Summarizer from '../Pages/Summarizer';
import Writer from '../Pages/Writer';
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import GrammaCheck from '../Pages/GrammaCheck';
import HelpIcon from './Icons.js'
import ContactIcon from './ContactIcon'
import AvatarIcon from './AvatarIcon'
import ProfilePage from '../Pages/ProfilePage';
import { Avatar, CircularProgress, FormControl, InputLabel } from '@material-ui/core';
import logo from '../Assets/logo.png'
import PurchaseCredits from '../Pages/PurchaseCredits';
import PurchaseCreditsPage from '../Pages/PurchaseCreditsPage';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { onValue, ref } from 'firebase/database';
import { auth, db } from '../Config/FirebaseConfig';
import Tests from '../Tests';

const Layout = ({users, keys, openAiKey}) => {
  const chargeRate = 0.03 //charge rate for openAi tools

  const navigate = useNavigate()
  //const [logedUser, setLogedUser] = useState(null)
  const logedUser = users?.find((user) => user.userId === auth.currentUser?.uid)

  //OPENAI API KEY
  const OPENAI_API_KEY_REF = keys.find((key) => key.NAME === 'openai')
  const OPENAI_API_KEY = OPENAI_API_KEY_REF?.KEY

  //DUPLICHECKER API KEY
  const DUPLICHECKER_API_KEY_REF = keys?.find((key) => key.NAME === 'duplichecker')
  const DUPLICHECKER_API_KEY = DUPLICHECKER_API_KEY_REF?.KEY

  //FLUTTERWAVE API KEY
  const FLUTTERWAVE_API_KEY_REF = keys?.find((key) => key.NAME === 'flutterwave')
  const FLUTTERWAVE_API_KEY = FLUTTERWAVE_API_KEY_REF?.KEY

  //PAYSTACK API KEY
  const PAYSTACK_API_KEY_REF = keys?.find((key) => key.NAME === 'paystack')
  const PAYSTACK_API_KEY = PAYSTACK_API_KEY_REF?.KEY

  //MONIFY API KEY
  const MONIFY_API_KEY_REF = keys?.find((key) => key.NAME === 'monify')
  const MONIFY_API_KEY = MONIFY_API_KEY_REF?.KEY

  const location = useLocation()

  const [toggleStatus, setToggleStatus] = useState(false)
  const [showNav, setShowNav] = useState(false)
  const [pageTitle, setPageTitle] = useState('')

  const toggle = () => {
    setToggleStatus(!toggleStatus)
  }

  const addCredits = (() => {
    navigate('/buy-credits')
  })

  useEffect(() => {
    if(location.pathname === '/paraphraser') {
      setPageTitle('Paraphraser')
    } else if(location.pathname === '/writer') {
      setPageTitle('Writer')
    }else if(location.pathname === '/check-plagiarism') {
      setPageTitle('Plagiarism Check')
    }else if(location.pathname === '/check-grammar') {
      setPageTitle('Grammar Check')
    }else if(location.pathname === '/summarizer') {
      setPageTitle('Summarizer')
    }else if(location.pathname === '/writer') {
      setPageTitle('Content Writer')
    }else if(location.pathname === '/buy-credits') {
      setPageTitle('Buy Credits')
    }else if(location.pathname === '/profile') {
      setPageTitle('Profile')
    }else if(location.pathname === '/help') {
      setPageTitle('Help Center')
    }else if(location.pathname === '/contact') {
      setPageTitle('Contact Us')
    }
  }, [location]);

  const GrammarIcon = (() => {
      return(
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.76689 11.7083H10.5086L6.25023 0.875H4.70023L0.441895 11.7083H2.18356L3.11689 9.20833H7.8169L8.76689 11.7083ZM3.75023 7.54167L5.47523 2.94167L7.20023 7.54167H3.75023ZM16.3836 8.03333L9.6419 14.775L6.58356 11.7083L5.40856 12.8833L9.65023 17.125L17.5586 9.20833L16.3836 8.03333Z" fill="#778086"/>
        </svg>
      )
   })

   const PlagIcon = (() => {
    return(
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.8346 4.23841L15.7617 0.165563C15.687 0.0745261 15.5805 0.0153319 15.4637 0H6.65578V6.82119L6.4571 6.88742C5.7644 7.10401 5.12249 7.45809 4.56977 7.92848C4.01706 8.39886 3.56489 8.97589 3.24031 9.62505C2.91573 10.2742 2.72541 10.9822 2.68073 11.7066C2.63606 12.431 2.73794 13.1569 2.98028 13.8411C3.07962 14.0066 3.04651 14.106 2.91406 14.2715L0.62929 16.5232C0.307385 16.8269 0.0941467 17.228 0.0223576 17.6647C-0.0494315 18.1014 0.0242024 18.5496 0.231939 18.9404C0.380775 19.2089 0.588871 19.44 0.84041 19.6161C1.09195 19.7921 1.38031 19.9085 1.68358 19.9564C1.98685 20.0043 2.29704 19.9824 2.59059 19.8924C2.88413 19.8024 3.1533 19.6467 3.37763 19.4371L5.76174 17.053C5.79432 17.0068 5.84082 16.9722 5.89443 16.9544C5.94805 16.9365 6.00598 16.9362 6.05975 16.9536C6.71258 17.2155 7.40936 17.3503 8.11273 17.351H20.0002V4.50331C19.9773 4.39852 19.9188 4.3049 19.8346 4.23841ZM16.0266 2.41722L17.5829 4.00662H16.0266V2.41722ZM3.70876 17.2185C3.31141 17.6159 2.91406 18.0464 2.48359 18.4437C2.40789 18.524 2.31333 18.5841 2.20849 18.6185C2.10366 18.653 1.99188 18.6607 1.88332 18.6409C1.77475 18.6212 1.67284 18.5746 1.58684 18.5055C1.50084 18.4364 1.43348 18.3468 1.39088 18.245C1.33434 18.1386 1.31348 18.0168 1.33136 17.8975C1.34924 17.7783 1.40493 17.668 1.49022 17.5828L3.74187 15.3311C4.03989 15.6291 4.3379 15.9603 4.66903 16.2583L3.70876 17.2185ZM7.98028 15.9934C5.82797 16.0596 4.00677 14.2053 4.00677 12.0199C4.00241 11.4981 4.10087 10.9805 4.29653 10.4967C4.49219 10.013 4.78123 9.57249 5.14713 9.20043C5.51304 8.82838 5.94864 8.53204 6.42907 8.32835C6.90951 8.12465 7.42536 8.01758 7.94717 8.01325C10.4968 8.04636 11.9538 9.86755 11.9869 11.9868C12.02 14.106 10.53 15.9272 7.98028 15.9934ZM18.6425 15.9934H11.5564C13.2783 14.3046 13.8081 12.351 12.9472 10.1325C12.0862 7.91391 10.3975 6.78808 8.0134 6.65563V1.35762H14.669V5.33113H18.6425V15.9934Z" fill="#778086"/>
        <path d="M8.0134 9.33775C7.31035 9.34612 6.63795 9.62687 6.13771 10.1209C5.63747 10.615 5.34838 11.2839 5.33128 11.9868C5.32195 12.34 5.3852 12.6914 5.51713 13.0192C5.64905 13.347 5.84683 13.6442 6.09823 13.8925C6.34964 14.1408 6.64932 14.3349 6.97874 14.4628C7.30816 14.5906 7.66031 14.6495 8.0134 14.6358C8.36663 14.6451 8.71802 14.5818 9.04582 14.4499C9.37363 14.318 9.67087 14.1202 9.91918 13.8688C10.1675 13.6174 10.3616 13.3177 10.4894 12.9883C10.6172 12.6589 10.6761 12.3067 10.6624 11.9536C10.6453 11.2595 10.3597 10.5991 9.86568 10.1112C9.37164 9.62338 8.70767 9.34611 8.0134 9.33775ZM7.98028 13.3444C7.63165 13.336 7.29962 13.1937 7.05302 12.9471C6.80643 12.7005 6.66418 12.3685 6.65578 12.0199C6.65573 11.8431 6.69105 11.6681 6.75967 11.5052C6.8283 11.3423 6.92883 11.1948 7.05535 11.0714C7.18187 10.948 7.33182 10.8511 7.49636 10.7865C7.66089 10.7219 7.83669 10.6909 8.0134 10.6954C8.18857 10.6908 8.36284 10.722 8.5256 10.7869C8.68835 10.8519 8.83618 10.9493 8.9601 11.0732C9.08401 11.1971 9.1814 11.3449 9.24635 11.5077C9.3113 11.6704 9.34245 11.8447 9.3379 12.0199C9.32926 12.3742 9.18242 12.711 8.92874 12.9585C8.67507 13.206 8.33468 13.3445 7.98028 13.3444Z" fill="#778086"/>
      </svg>
    )
 })

  return (
    <div className='layout'>
      <div className={`body-area${showNav ? ' body-pd' : ''}`}>
        {/* LAYOUT HEADER */}
      <header className='header'>
        <div className='first'>
          <div className="header_toggle">
            <i className={`bi ${showNav ? 'bi-x' : 'bi-list'}`} onClick={() => setShowNav(!showNav)} />
          </div>
          <img src={logo} alt='Logo' className='logo'/>
          <h3 className='name'>Retexter</h3>
        </div>
        <div className='second'>
          <h3 className='page-title'>{pageTitle}</h3>
        </div>
        <div className='third'>
          <button onClick={addCredits} className='addcredit-btn'><img src={star} 
          alt='star' className='star' />Add Credits</button>
          <img src={credit} alt='cred' className='credits-icon' />
          {!logedUser && <h3 className='credits'>0 <span>Credits</span></h3>}
          {logedUser?.balance >= 1000 && <h3 className='credits'>{`${parseFloat(parseFloat(logedUser?.balance).toFixed() / 1000).toFixed(2)}K`} <span>Credits</span></h3>}
          {logedUser?.balance < 1000 && <h3 className='credits'>{parseFloat(logedUser?.balance).toFixed(2)} <span>Credits</span></h3>}
          <NavLink to="/profile" className="avatar-navlink" >
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Avatar className='nav_icon' style={{backgroundColor: '#1177BB', color: '#fff', width: '25px', height: '25px', fontSize: '9px'}}>{logedUser?.userName[0]}</Avatar>
              </div>
            </NavLink>
        </div>
      </header>
      {/* SIDE BAR */}
      <div className={`l-navbar${showNav ? ' show' : ''}`}>
        <nav className="nav">
          <div>
            <div className="nav_list">
              <NavLink to="/paraphraser" className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
                <i className='bi bi-arrow-repeat nav_icon' /><span className="nav_name">Paraphraser</span>
              </NavLink>
              <NavLink to="check-plagiarism" className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
                <PlagIcon className='bi bi-vector-pen nav_icon' /><span className="nav_name">Plagiarism Check</span>
              </NavLink>
              <NavLink to="check-grammar" className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
                <GrammarIcon className='bi bi-vector-pen nav_icon' /><span className="nav_name">Grammar Check</span>
              </NavLink>
              <NavLink to="summarizer" className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
                <i className='bi bi-justify-left nav_icon' /><span className="nav_name">Summarizer</span>
              </NavLink>
              <NavLink to="writer" className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
                <i className='bi bi-vector-pen nav_icon' /><span className="nav_name">Content Writer</span>
              </NavLink>
            </div>
            <div className='mobile-addcredits-link'>
              <NavLink to="/buy-credits" className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
                <h6 className="nav_name addcredits-link">Add Credits</h6>
              </NavLink>
            </div>
          </div>
          <div>
            <NavLink to="https://retexter.ai/help-centre/" target={'_blank'} className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
              <HelpIcon className='bi bi-box-arrow-left nav_icon' /><span className="nav_name">Help Center</span>
            </NavLink>
            <NavLink to="https://retexter.ai/contact-us/" target={'_blank'} className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
              <ContactIcon className='bi bi-box-arrow-left nav_icon' /><span className="nav_name">Contact Us</span>
            </NavLink>
            <NavLink to="/profile" className="nav_link nav-link" onClick={(() => {setShowNav(false)})}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Avatar className='nav_icon' style={{backgroundColor: '#1177BB', color: '#fff', width: '25px', height: '25px', fontSize: '15px'}}>{logedUser?.userName[0]}</Avatar>
                <span className="nav_name" style={{marginLeft: '15px'}}>Profile</span>
              </div>
            </NavLink>
          </div>
        </nav>
      </div>
      {/* DISPLAY PAGES IN THE LAYOUT */}
      {keys.length === 0 ? <div style={{width: "100%", height: "80vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <CircularProgress className='loader'/>
        <h3>Loading User Data...</h3>
      </div> : <div className="pt-4 pb-4">
        <Routes>
          <Route exact path="/test" element={<Tests OPENAI_API_KEY={OPENAI_API_KEY}/>} />
          <Route exact path='/paraphraser' element={<Pharapraser logedUser={logedUser} OPENAI_API_KEY={OPENAI_API_KEY} chargeRate={chargeRate} openAiKey={openAiKey}/>} />
          <Route exact path='/check-plagiarism' element={<PlagCheck logedUser={logedUser}  DUPLICHECKER_API_KEY={DUPLICHECKER_API_KEY}/>} />
          <Route exact path='/writer' element={<Writer logedUser={logedUser} OPENAI_API_KEY={OPENAI_API_KEY} chargeRate={chargeRate}/>} />
          <Route exact path='/summarizer' element={<Summarizer logedUser={logedUser} OPENAI_API_KEY={OPENAI_API_KEY} chargeRate={chargeRate}/>} />
          <Route exact path='/check-grammar' element={<GrammaCheck logedUser={logedUser} OPENAI_API_KEY={OPENAI_API_KEY} chargeRate={chargeRate}/>} />
          <Route exact path="/profile" element={<ProfilePage users={users}/>} />
          <Route exact path="/buy-credits" element={<PurchaseCredits users={users}/>} />
          <Route exact path="/pay-now" element={<PurchaseCreditsPage users={users} MONIFY_API_KEY={MONIFY_API_KEY}
          PAYSTACK_API_KEY={PAYSTACK_API_KEY} FLUTTERWAVE_API_KEY={FLUTTERWAVE_API_KEY} 
          />} />
        </Routes>
      </div>}
    </div>
  </div>
  )
}

export default Layout