import React, { useState } from 'react'
import { useNavigate} from 'react-router-dom'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {  IconButton } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Alert } from '@mui/material';
import logo from '../Assets/logo.png'
import background from '../Assets/background.png'
import {signInWithEmailAndPassword, sendEmailVerification, signOut} from 'firebase/auth'
import { auth } from '../Config/FirebaseConfig'

const useStyles = makeStyles({
  loginPage: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  signupForm: {
    width: '40%',
    height: '32em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '8px',
    '@media (max-width: 500px)': {
      width: '100%',
      height: '100%'
  },
  '@media (max-width: 800px)': {
    width: '100%',
},
  },
  error: {
    color: 'red',
  },
  heading: {
    color: '#000929',
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    fontFamily: 'poppins'
  },
  welcomeImg: {
    width: '100px',
    height: '100px',
    backgroundColor: 'transparent',
  },
  signupText2: {
    fontSize: '18px',
    fontFamily: 'poppins',
    margin: '10px'
  },
  form: {
      width: '90%',
      maxWidth: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
  },
  input: {
      width: '90%',
      margin: '.7rem',
      padding: '.7rem',
  },
  link: {
    alignSelf: 'flex-start',
    margin: '10px 20px',
    textDecoration: 'none',
    fontSize: '14px',
    '@media (max-width: 500px)': {
      fontSize: '13px',
  },
  },

  signUpLink: {
    alignSelf: 'flex-start',
    margin: '10px 20px',
    textDecoration: 'none',
    fontSize: '14px',
    color:'#001',
    fontWeight: '500',
    '@media (max-width: 500px)': {
      fontSize: '13px',
  },

  "&:hover": {
    color:'#000'
     }

  },

  button: {
    width: '90%',
    margin: '5px',
    padding: '10px',
    fontSize: '15px',
    borderRadius: '5px',
    backgroundColor: '#1177BB',
    color: '#ffffff',
    "&:hover": {
      backgroundColor: "#003399"
       }
  },
 
  
})

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false)

    // PassWord Visibility States
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const classes = useStyles()
    let navigate = useNavigate();

      const login = e => {
        e.preventDefault()
        setLoading(true)
        signInWithEmailAndPassword(auth, email.trim(), password)
        .then(() => {
          if(!auth.currentUser.emailVerified) {
            sendEmailVerification(auth.currentUser)
            .then(() => {
              navigate('/verifyemail')
            })
          .catch(err => {
            if (err.message === 'auth/invalid-email') {
              setError('Enter a valid Email Address')
            }
            if(err.message === 'Firebase: Error (auth/network-request-failed).') {
              setError('Network request failed, check your connection')
            }
          })
        }else{
            sessionStorage.setItem('isAuth', auth.currentUser.uid)
            navigate('/paraphraser')
        }
        })
        .catch(err => {
          setLoading(false)
          if (err.message === "Firebase: Error (auth/invalid-email).") {
            setLoading(false)
            setError('Enter a valid Email Address')
            }else if(err.message === " Firebase: Error (auth/weak-password).") {
              setLoading(false)
              setError('Password should be at least 6 characters')
            } else if(err.message === 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).') {
              setLoading(false)
              setError('this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.')
            } else if(err.message === 'Firebase: Error (auth/network-request-failed).') {
              setLoading(false)
              setError('Network request failed, check your connection')
            } else if(err.message === 'Firebase: Error (auth/user-not-found).') {
              setLoading(false)
              setError('This email is not registered')
            }
            else {
              setLoading(false)
              //console.log(err.message);
              setError('Something went wrong, make sure you have network connection and your details are correct, then try again')
            }
        })
      }
      const Error = (() => { // Error Component
              if(error !== '') {
                return(
                  <Alert severity="error">{error}</Alert>
                )
              }
             })
return (
  <div className = {classes.loginPage}>
    <div className={classes.signupForm}>
    <img src={logo} alt='Retexter' className={classes.welcomeImg} />
    <h3 className={classes.signupText2}>Welcome Back</h3>
    <Box
        component="form"
        noValidate
        autoComplete="off"
        className = {classes.form}
    >
      <Error />
      <TextField className={classes.input} id="outlined-basic email" label="Email Address" variant="outlined" 
      onChange={(e) => setEmail(e.target.value)} required/>
      <TextField className={classes.input} id="password" label="Password" required
            variant="outlined" onChange={(e) => setPassword(e.target.value)} 
            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
            InputProps={{ // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            />
        <NavLink className={classes.link} to='/resetpassword'>Forget Password?</NavLink>
        {loading ? <Button variant="disabled" className = {classes.button}>Signing in...</Button>
        : <Button variant="contained" onClick={login} className = {classes.button}>Continue</Button> }
        <NavLink className={classes.signUpLink} to='/signup'>Don't have an Account?<span style={{fontWeight:'600', color:'#001'}}> Sign Up</span></NavLink>
      </Box>
    </div>
  </div>
  )
}

export default SignUp