import React, { useState } from 'react'
import {sendPasswordResetEmail} from 'firebase/auth'
import {useNavigate, NavLink} from 'react-router-dom'
import { auth } from '../Config/FirebaseConfig'
import { makeStyles } from '@material-ui/core/styles'
import { Box, TextField, Button } from '@material-ui/core'
import { Alert } from '@mui/material'
import background from '../Assets/background.png'

const useStyles = makeStyles({
    formContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#eee',
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '@media (min-width: 780px)' : {
        // marginTop: '150px',
    },
    },
    heading: {
      color: '#1177BB',
      fontSize: '1.5rem',
      textTransform: 'capitalize',
      fontFamily: 'poppins',
      textAlign: 'center',
    },
    form: {
      width: '80%',
      maxWidth: '500px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '@media (max-width: 500px)': {
        minHeight: '75vh',
        width: '100%',
      },
      '@media (min-width: 500px)': {
        minHeight: '75vh',
    },
    },
    input: {
        width: '70%',
        color: '#1177BB',
        margin: '0.5rem',
        padding: '10px',
    },
    link: {
        margin: '0 0 2rem 0',
        color: '#1177BB',
        textDecoration: 'none',
        pointer: 'cursor',
    },
    button: {
      width: '200px',
      margin: '5px',
      padding: '10px',
      fontSize: '15px',
      borderRadius: '5px',
      backgroundColor: '#1177BB',
      color: '#ffffff',
      border: 'none',
      outline: 'none',
      pointer: 'cursor',
      "&:hover": {
        backgroundColor: "#003399"
         }
    },
  })

const ResetPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const classes = useStyles()

    const resetPassword = e => {  //function to reset user password
        e.preventDefault()
        sendPasswordResetEmail(auth, email) //this sends password reset link to the registered email
        .then( () => {
          navigate('/login')  //navigate user to the login page if he reset the password
        })
        .catch(err => {  //catch errors
          if (err.message === "Firebase: Error (auth/invalid-email).") {
            setError('Enter a valid Email Address')
            } else if(err.message === 'Firebase: Error (auth/network-request-failed).') {
              setError('Network request failed, check your connection')
            } else if(err.message === 'Firebase: Error (auth/user-not-found).') {
              setError('This email is not registered')
            }
            else {
              //console.log(err.message);
              setError('Something went wrong, make sure you have network connection and your details are correct, then try again')
            }
        });
      }
      
      const Error = (() => {
        if(error !== '') {
          return(
            <Alert severity="error">{error}</Alert>
          )
        }
       })

    return (
      <div className = {classes.formContainer}>
      <Box
          component="form"
          // sx={{
          //     '& > :not(style)': { m: 1, width: '25ch' },
          // }}
          noValidate
          autoComplete="off"
          className = {classes.form}
      >
              <h3  className = {classes.heading}>Password Reset</h3>
              <Error />
              <TextField className={classes.input} id="email" label="Enter Your Email" variant="outlined" onChange={(e) => setEmail(e.target.value)}/>
              <Button onClick={resetPassword} className = {classes.button} variant='outlined'>Reset Password</Button>
              <NavLink className={classes.link} to='/'><Button>Back To Home</Button></NavLink>
      </Box>
      <div className={classes.dumb}></div>
  </div>
    )
}

export default ResetPassword
