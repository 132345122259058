import { Alert } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PieChart } from 'react-minimal-pie-chart';
import DonutChart from 'react-donut-chart';
import '../Assets/Styles/PlagCheck.css'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { saveAs } from 'file-saver';
import { CircularProgress } from '@material-ui/core';
import { ref, update } from '@firebase/database';
import { auth, db } from '../Config/FirebaseConfig';
import CreditAlert from '../Components/CreditAlert';
import Highlighter from '../Components/Highlighter';

const PlagCheck = ({logedUser, DUPLICHECKER_API_KEY}) => {
  const navigate = useNavigate()
  const endpoint_url = 'https://www.duplichecker.com/api/checkplag'
  const [ text, setText ] = useState('')
  const [hash, setHash] = useState('')
  const [key, setKey] = useState('')
  const [newHash, setNewHash] = useState('')
  const [newKey, setNewKey] = useState('')
  const [result, setResult] = useState([])
  const [recall, setRecall] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isRunning, setIsRunning] = useState(false)
  const formdata = new FormData()
  const location = useLocation()

  const [insufficientCredits, setInsufficientCredits] = useState(false)
  let creditsUsed = 0
  const userCredits = logedUser?.balance

  const deductCredits = (() => {
    //const userCredits = logedUser?.balance
    if (userCredits >= creditsUsed) {
      const newBalance = userCredits - creditsUsed
       update(ref(db, `Users/${auth.currentUser.uid}`), { // firebase function to update the user in the database
         balance: newBalance,
       })
    } else {
      setInsufficientCredits(true)
    }
  })

  //this function passes the props
  useEffect(() => {
    if(location.state !== null) {
      console.log(location.state.prompt);
      setText(location.state.prompt);
      //console.log(text);
    }
  }, []);

  //this function downloads text to storage
  const randomNumber = Math.random() * 100;
  function downloadStringAsFile(stringToDownload, randomNumber) {
    const blob = new Blob([stringToDownload], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, randomNumber);
  }

  const handleDownload = (() => {
    downloadStringAsFile(text, `${randomNumber}.txt`);
  })
  formdata.append("token", DUPLICHECKER_API_KEY)
  formdata.append("data", text)

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirct: 'follow',
    Headers: {
      'Content-Type':'application/json',
      'Access-Control-Allow-Origin':'*',
    },
    // mode: 'no-cors'
   }

   const check = (() => {
    if (text !== '') {
      if (userCredits >= text.split(' ').length * 0.03) {
        setLoading(true)
        fetch(endpoint_url, requestOptions)
        .then(response => response.text())
        .then(result => {
          var data = JSON.parse(result)
          //console.log(data);
          setHash(data.hash)
          setKey(data.key)
          setRecall(data.recall)
          setIsRunning(true)
          getResult(data.hash, data.key)
        })
        .catch(err => setError(err.message))
      } else {
        setInsufficientCredits(true)
        setLoading(false)
      }
    } else{
      setError('type the text to check')
    }
  })

  const getResult = ((hash, key) => {
      //console.log(key, hash);
      setNewKey('')
      setNewHash('')
    var options = {
      method: "GET",
      Headers: {
        'Content-Type':'application/json',
        'Access-Control-Allow-Origin':'*',
        'Access-Control-Allow-Methods':'POST,Get,PATCH,OPTIONS'
      },
      //redirect: 'follow',
      //mode: 'no-cors',
    }
    const fetchData = async (url) => {
      try {
        const response = await fetch(url, options);
        const result = await response.text();
        const data = JSON.parse(result);
        //console.log(data);
        setNewKey(data.key)
        setNewHash(data.hash)
        if (data.recall === false) {
          creditsUsed = text.split(" ").length * 0.1
          deductCredits()
          setResult(data);
          setLoading(false)
          //console.log(result);
        } else {
          //console.log(newKey, newHash);
          //console.log(data.key, data.hash);
          const newUrl = `https://www.duplichecker.com/api/query-footprint/${data.hash}/${data.key}`;
          await fetchData(newUrl);
        }
      } catch (err) {
        setError(err.message)
        setLoading(false)
      }
    };
    
    const initialUrl = `https://www.duplichecker.com/api/query-footprint/${hash}/${key}`;
    fetchData(initialUrl);
  })

  //Error Component
  const Error = (() => {
    if(error !== '') {
      return(
        <Alert severity="error">{error}</Alert>
      )
    }
   })

  //the functions below gets doc from device and read it
  const fileInputRef = useRef();
  
  function handleButtonClick() {
    fileInputRef.current.click();
  }

  function handleFileSelect(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = handleFileRead;
    reader.readAsText(file);
  }

  function handleFileRead(event) {
    const content = event.target.result;
    setText(content)
    //console.log(prompt); // do something with the file content
    setIsButtonVisible(false)

  }

   //this function count words in the textarea
   function countWords() {
    return text.split(" ").length;
  }

  const [selectedButton, setSelectedButton] = useState('plagiarismchecker')

 const handleChangeButton = ((e) => {
  setSelectedButton(e.target.value)
 })

  //THESE FUNCTIONS REDIRECT USERS
  const redirectToPharaphraser = (() => {
    navigate('/pharaphraser', {state: {prompt: text}})
  })
  const redirectToSummarizer = (() => {
    navigate('/summarizer', {state: {prompt: prompt}})
  })
  const redirectToGrammaCheck = (() => {
    navigate('/grammacheck', {state: {prompt: prompt}})
  })

  const startNewSearch = (() => {
    setResult([])
    setText('')
  })

  const data = [
    { title: 'uqique', value: parseInt(result.uniquePercent), color: 'green' },
    { title: 'plagiarized', value: parseInt(result.plagPercent), color: 'red' },
    { title: 'Three', value: 0, color: '#6A2135' },
  ];

  let resultArray = []

  console.log(result);

  //this function convert the palagiarised text in to array
  const resultToArray = (() => {
    if (result.length !== 0) {
      result.details.map((detail) => {
        if (detail.unique === 'false') {
          let array = detail.query.split(' ')
          console.log(array);
          resultArray.push(...array)
        }
        return
      })
    }
  })

  resultToArray()

  console.log(resultArray);

  const plagiarizedText = resultArray.join(' ')

  // console.log(formattedText);

  const splittedText = text.split('.')
  const splittedPlagiarizedText = plagiarizedText.split('.')

  console.log(splittedText);
  console.log(plagiarizedText.split('.'));

  // if (result.length !== 0) {
  //   for (let i = 0; i < splittedText.length; i++) {
  //     for (let j = 0; j < splittedPlagiarizedText.length; j++) {
  //       if (splittedPlagiarizedText[j] === splittedText[i]) {
  //         console.log(splittedPlagiarizedText[j]);
  //       }
  //     }
  //   }  
  // }

  return (
    <>
    <div className='second-header-cont'>
        <FormControl className='second-header'>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedButton}
            onChange={handleChangeButton}
            className="select"
            style={{color: '#fff'}}
          >
            {/* <MenuItem className="select-item" value={'contentwriter'}>Content Writer</MenuItem> */}
            <MenuItem className="select-item" value={'plagiarismchecker'}>Plagiarism Checker</MenuItem>
            <MenuItem className="select-item" value={'grammachecker'} onClick={redirectToGrammaCheck}>Grammar Check</MenuItem>
            <MenuItem className="select-item" value={'summarizer'} onClick={redirectToSummarizer}>Summarizer</MenuItem>
            <MenuItem className="select-item" value={'paraphraser'} onClick={redirectToPharaphraser}>Paraphraser</MenuItem>
          </Select>
        </FormControl>    
      </div>
    {loading ?<div className='loader-page'>
    <CircularProgress className='loader'/>
    <p>Making the magic... <br/>Please Wait</p>
    </div> :
    <>
    {insufficientCredits ? <CreditAlert logedUser={logedUser} setInsufficientCredits={setInsufficientCredits}/> : <div className='main'>
      {result.length !== 0 ? <>
      <div className='check-result'>
        <h5>Scan Result</h5>
        <div className='scan-properties'>
          <div className='properties'>
            <div className='property'>
              <h4>Scan properties</h4>
            </div>
            <div className='words'>
              <h4>Number Of Words: {countWords()}</h4>
              <h4>Result Found: {result.details?.length}</h4>
            </div>
          </div>
          <div className='percentages'>
            <div className='percentage-circle'>
            <PieChart
              data={data}
              className='piechart'
            />
            </div>
            <div className='percentage-info'>
              <div className='plag-percentages'>
                <div className='plagiarized'>
                  <h4 className='percent'>{result.plagPercent}%</h4>
                  <div className='plag-status'>
                    <div className='red'></div>
                    <h5>plagiarized</h5>
                  </div>
                </div>
                <div className='unique'>
                <h4 className='percent'>{result.uniquePercent}%</h4>
                  <div className='plag-status'>
                    <div className='green'></div>
                    <h5>Unique</h5>
                  </div>
                </div>
              </div>
              <div className='plag-actions'>
                <button className='plag-action-button-1' onClick={startNewSearch}>Start New Search</button>
                <div className='plag-action-buttons'>
                  <button className='plag-action-button' style={{display:'none'}}>Remove Plagiarism</button>
                  <button className='plag-action-button' onClick={redirectToPharaphraser}>Paraphrase</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='scan-result'>
          <div className='submitted-text'>
            <div className='heading'>
              <h6>Submitted Text</h6>
            </div>
            <div className='text-container'> 
              <Highlighter text={text} plagiarizedText={plagiarizedText} />
            </div>
            {/* <div className='heading2'>
              <h6>Plagiarized Text</h6>
            </div>
            <div className='text-container2'>
              <Highlighter text={text} plagiarizedText={plagiarizedText} />
            </div> */}
          </div>
          <div className='results'>
            <div className='plag-heading'>
              <h6>Results</h6>
            </div>
            <div className='results-container'>
              {result.details && result.details?.map((detail => {
                return(
                  <div>
                     {detail.webs?.map((web => {
                      return (
                        <a href={web.url} target='_blank' rel='noreferrer' className='link'>
                          <p className='link-title'>{web.title}</p>
                          <p className='link-des'>{web.des}</p>
                          <p className='link-url'>{web.url}</p>
                        </a>
                      )
                    }))
                  }
                  </div>
                ) 
              }))}
            </div>
          </div>
        </div>
      </div>
      <div className='plag-result-buttons'>
      <button className='result-button'onClick={handleDownload}>Download pdf report</button>
      <button className='result-button' onClick={handleDownload}>Download Word Report</button>
    </div>
      </> : <>
      <div className='check-query'>
      <textarea className='text-area' placeholder='Paste your text here!' rows={100} 
      value={location?.state?.prompt} onChange={(e) => setText(e.target.value)}>
      </textarea>
      <Error />
      <div className='plag-word-count-cont'>
        <h6 className='plag-word-count' >Total words {countWords()}</h6>
      </div>
      
      <input type="file" accept=".txt" ref={fileInputRef} onChange={handleFileSelect} style={{ display: "none" }} />
      
      {text !== '' ? <button className='upload-file-button' onClick={check}>Check Plagiarism</button>
      : <div><p className='or' style={{textAlign:'center'}}>Or</p>
      <button className='upload-file-button' onClick={handleButtonClick}>Upload File</button></div>}
      </div>     
      </>
      }
    </div>}
    </>}
    </>
  )
}

export default PlagCheck