import React, { useState } from 'react'
import '../Assets/Styles/Writer.css'
import { Alert } from '@mui/material'
import OpenAI from "openai";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EngImg from '../Assets/Icons/EngImg.png'
import FrnchImg from '../Assets/Icons/FrnchImg.jpg'
import SpnshImg from '../Assets/Icons/SpnshImg.png'
import GrmnImg from '../Assets/Icons/GrmnImg.png'
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { ref, update } from '@firebase/database';
import { auth, db } from '../Config/FirebaseConfig';
import CreditAlert from '../Components/CreditAlert';

const Writer = ({logedUser, OPENAI_API_KEY, chargeRate}) => {
  const navigate = useNavigate()

  const configuration = {
    apiKey: OPENAI_API_KEY,
    "dangerouslyAllowBrowser": true
  }
  const openai = new OpenAI({apiKey: OPENAI_API_KEY, "dangerouslyAllowBrowser": true});
  const [loading, setLoading] = useState(false)

  const [prompt, setPrompt] = useState('')
  const [result, setResult] = useState('')
  const [prompts, setPrompts] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState('English');

  const [insufficientCredits, setInsufficientCredits] = useState(false)
  const userCredits = logedUser?.balance
  let creditsUsed = 0

  const [error, setError] = useState('')

  const handleChangeLanguage = (e) => {
    setSelectedLanguage(e.target.value);
    
  };

  const deductCredits = (() => {
    //const userCredits = logedUser?.balance
    if (userCredits >= creditsUsed) {
      const newBalance = userCredits - creditsUsed
        update(ref(db, `Users/${auth.currentUser.uid}`), { // firebase function to update the user balance in the database
          balance: newBalance,
        })
    } else {
      setInsufficientCredits(true)
    }
  })
    

  const write = async () => {
    if (prompt === '') {
      setError('Type something to generate text!!!')
    } else {
      if (prompt !== '') {
        if ( userCredits >= prompt.split(" ").length * chargeRate) {
          setInsufficientCredits(false)
          setLoading(true)
          setPrompt('')
          try {
            const response = await openai.chat.completions.create({
              model: "gpt-4-1106-preview",
              messages: [{ role: "user", content: `${prompt} in ${selectedLanguage}` }],
              //prompt: `${prompt} in ${selectedLanguage}`,
              temperature: 0.5,
              max_tokens: 4000,
              top_p: 1,
              frequency_penalty: 0.0,
              presence_penalty: 0.0,
          });
          creditsUsed = response.usage.total_tokens * chargeRate
          deductCredits()
          setResult(response.choices[0].message.content);
          setLoading(false)
          const newPrompt = {
            title: prompt,
            result: response.choices[0].message.content,
            key: prompt,
            }
          //const updatedPrompt = [...prompts, newPrompt]
    
          setPrompts([...prompts, newPrompt])
        } catch (err) {
          setError(err.message)
          setLoading(false)
        }
        } else {
          setInsufficientCredits(true)
          setLoading(false)
        }
      } else {
        setError('Enter Some Text')
      }
    }
  };

  const copy = async (result) => {
    try {
      await navigator.clipboard.writeText(result);
      alert("Text copied")
    } catch (err) {
      alert('Failed to copy: ', err)
    }
  }

  const Error = (() => {
    if(error !== '') {
      return(
        <Alert severity="error">{error}</Alert>
      )
    }
   })

   const [selectedButton, setSelectedButton] = useState('contentwriter')

 const handleChangeButton = ((e) => {
  setSelectedButton(e.target.value)
 })

  //THESE FUNCTIONS REDIRECT USERS
  const redirectToPharaphraser = (() => {
    navigate('/paraphraser', {state: {prompt: result}})
  })
  const redirectToSummarizer = (() => {
    navigate('/summarizer', {state: {prompt: result}})
  })
  const redirectToPlagcheck = (() => {
    navigate('/check-plagiarism', {state: {prompt: result}})
  })
  const redirectToGrammaCheck = (() => {
    navigate('/check-grammar', {state: {prompt: result}})
  })

  
  return (
    <>
      <div className='second-header-cont'>
        <FormControl className='second-header'>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selectedButton}
            onChange={handleChangeButton}
            className="select"
            style={{color: '#fff'}}
          >
            <MenuItem className="select-item" value={'contentwriter'}>Content Writer</MenuItem>
            <MenuItem className="select-item" value={'summarizer'} onClick={redirectToSummarizer}>Summarizer</MenuItem>
            <MenuItem className="select-item" value={'pharaphraser'} onClick={redirectToPharaphraser}>Paraphraser</MenuItem>
            <MenuItem className="select-item" value={'grammachecker'} onClick={redirectToGrammaCheck}>Grammar Checker</MenuItem>
            <MenuItem className="select-item" value={'plagiarismchecker'} onClick={redirectToPlagcheck}>Plagiarism Checker</MenuItem>
          </Select>
        </FormControl>    
      </div>
    <div className='writer-main'>
      <div className='writer-container'>
        <div className='writer-heading'>
          <div className='writer-texts'>
            <h4 className='writer-header'>Generate Content With AI</h4>
            <h6 className='writer-description'>Turn a title into a long and engaging Content.</h6>
          </div>
          <div className='dropdown-container'>
            <FormControl className='tones-form' style={{width: '50%', border: 'none', outline: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <Select
                variant='standard'
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedLanguage}
                onChange={handleChangeLanguage}
                className="select-tone"
                style={{border: 'none', padding: '12px',}}
              >
                <MenuItem className="select-item" value={'English'}><img src={EngImg} alt='Eng' className='lang-img'/> English</MenuItem>
                <MenuItem className="select-item" value={'French'}><img src={FrnchImg} alt='Grmn' className='lang-img'/>French</MenuItem>
                <MenuItem className="select-item" value={'Spanish'}><img src={SpnshImg} alt='Spnsh' className='lang-img'/>Spanish</MenuItem>
                <MenuItem className="select-item" value={'German'}><img src={GrmnImg} alt='Frnch' className='lang-img'/>German</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {insufficientCredits ? <CreditAlert logedUser={logedUser} setInsufficientCredits={setInsufficientCredits}/> : <div className='writer'>
           {prompts.length > 0 ? <div className='dialogues'>
            {
               prompts?.map((prompt) => {
                return(
                  <div className='responses' key={prompt.key}>
                    <div className='user'>
                      <p>{prompt.title}</p>
                    </div>
                    <div className='ai'>

                    <TextareaAutosize
                         readOnly
                          className='textArea'
                          minRows={1} // Set the initial number of rows
                          // maxRows={6} // Set the maximum number of rows
                          style={{ resize: 'none' }} // Prevent manual resizing
                          value={prompt.result.trim()}
                        />

                      <i className='bi bi-clipboard copy-icon' onClick={() => {copy(prompt.result)}}></i>
                    </div>
                  </div>
                )
              })
            }
          </div> : <div className='dialogues'>

         
            <div className='responses'>
              <Error />
              <p className='dumb-text'>Type something in the text field to start...</p>
            </div>
            </div>}
          <div className='inputs'>
            <div className='input-container'>
              <input type='text' className='input' 
              placeholder='Chat with me...'
              onChange={(e) => setPrompt(e.target.value)}
              value={prompt}>
              </input>
              {loading? <h5 style={{color: '#1177bb', fontSize: '35px'}}>...</h5> : <i className="bi bi-send send-icon" onClick={write}></i>}
            </div>
          </div>
        </div>}
      </div>
    </div>
    </>
  )
}
export default Writer
