import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, FormControl, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@mui/material';

    const plan350 = [
        "Upto 174,000 Words",
        "Plagiarism Checks",
        "Standard support",
        "Faster Processing speed",
        "Secure Payments",
        "Limited account opening",
    ]

    const plan500 = [
        "Upto 274,000 Words",
        "Plagiarism Checks",
        "Standard support",
        "Faster Processing speed",
        "Secure Payments",
        "Limited account opening",
    ]

    const plan1200 = [
        "Upto 475,000 Words",
        "Swift Support",
        "Unlimited Plagiarism Checks",
        "Faster Processing speed",
        "Secure Payments",
        "unlimited account sharing",
    ]
    const customPlan = [
        "Pay what you want",
        "Unlimited Plagiarism Checks",
        "Standard and swift Support",
        "Faster Processing speed",
        "Secure Payments",
        "Faster Processing Speed",
    ]

    const questions = [
        {'id': 1 , 'question': "What payment methods do you accept?", 'answer': ' We currently accept payment by credit card, including Visa, Verve, Mastercard, and bank transfer options'},
        {'id': 2 , 'question': ' Is my payment information secure?', 'answer': 'Yes. We use standard encryption and security protocols to protect your payment information and keep it safe from unauthorized access.'},
        {'id': 3 , 'question': 'What is credit? ', 'answer': "A credit is a unit of currency used in Retexter's pay-as-you-go system. When you subscribe to Retexter, you'll be given a certain number of credits, depending on the plan you choose."},
        {'id': 4 , 'question': 'How does the pay-as-you-go system work?', 'answer': "When you subscribe to Retexter, you'll be given a certain number of credits, depending on the plan you choose. You will then be charged for those credits as you access the various features. When you run out of credits, you can purchase more at any time."},
        {'id': 5 , 'question': 'How much do credits cost? ', 'answer': ' The cost of credits varies depending on the plan you choose. We offer different pricing plans based on the number of credits you need, and a costume option so you can choose the one that best fits your needs.'},
        {'id': 6 , 'question': 'How do I purchase more credits?', 'answer': 'You can purchase more credits from your account dashboard. Simply click on the add credit option, select the number of credits you need, and complete the checkout process using your preferred payment method.'},
        {'id': 7 , 'question': 'Can I track my credit usage and balance?', 'answer': 'Yes, you can track your credit usage and balance from your account dashboard.'},
        {'id': 8 , 'question': "Do you offer refunds if I'm not satisfied with the service?", 'answer': 'No. However, we are always looking for ways to improve our tool and provide the best possible experience for our users.'},
        {'id': 9 , 'question': 'Is there a refund policy for unused credits?', 'answer': 'No, we do not offer refunds for unused credits. However, you get a rollover of unused credit whenever you purchase a new plan.'},
    ]   

const useStyles = makeStyles ({
    addCreditsPage: {
        width: '100%',
        minHeight: '66vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    purchaseHeading: {
        margin: '30px auto 20px auto',
        fontSize: '40px',
        textAlign: 'center',
    },

    purchaseHeading2: {
        margin: '10px auto 20px auto',
        fontSize: '20px',
        textAlign: 'center',
    },

    currencyButtons: {
        width: '120px',
        height: '40px',
        border: '2px solid #1177BB',
        borderRadius: '20px',
        display: 'flex',
        margin: '30px auto 20px auto'
    },

    NgnCurrencyButton: {
        margin: '0',
        padding: '0',
        minWidth: '50%',
        width: '50%',
        height: '100%',
        border: 'none',
        outline: 'none',
        background: 'none',
        borderRadius: '20px 0 0 20px',
        '&:active': {
            backgroundColor: ' #1177BB',
        },
        '&:focus': {
            backgroundColor: ' #1177BB',
        },
    },

    usdCurrencyButton: {
        margin: '0',
        padding: '0',
        minWidth: '50%',
        width: '50%',
        height: '100%',
        border: 'none',
        outline: 'none',
        background: 'none',
        borderRadius: '0 20px 20px 0',
        '&:active': {
            backgroundColor: ' #1177BB',
        },
        '&:focus': {
            backgroundColor: ' #1177BB',
        },
    },

    currencyPlans: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 700px)' : {
            flexDirection: 'column',
        },
        '@media (max-width: 1100px)' : {
            flexWrap: 'wrap',
        },
    },

    currencyPlan: {
        width: '90%',
        minHeight: '600px',
        margin: '20px 10px',
        border: '2px solid #ccc',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // '@media (max-width: 700px)' : {
        //     width: '90%',
        // },
        '@media (min-width: 700px)' : {
            width: '45%',
        },
    },

    credits: {
        fontSize: '30px',
        fontWeight: '600',
        marginTop: '30px',
    },

    creditText: {
        fontSize: '15px',
        fontWeight: '300',
        marginTop: '-10px',
    },

    amount: {
        fontSize: '25px',
        fontWeight: '500',
    },

    customAmount: {
        fontSize: '18px',
        fontWeight: '500',
    },

    creditButton: {
        width: '80%',
        padding: '10px',
        margin: '20px 10px',
        backgroundColor: '#1177BB',
        color: '#fff',
        border: 'none',
        outline: 'none',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#1188BB',
          },
    },

    customCreditButton: {
        width: '80%',
        padding: '10px',
        margin: '20px 10px',
        backgroundColor: '#e9e769',
        color: '#000',
        border: 'none',
        outline: 'none',
        borderRadius: '50px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#e9d779',
          },
    },

    divider: {
        width: '90%',
        height: '3px',
        margin: '10px',
    },

    planFeatures: {
        margin: '20px auto'
    },

    feature: {
        fontSize: '14px',
        margin: '10px',
        '@media (min-width: 400px)' : {
            fontSize: '16px',
        },
        '@media (min-width: 700px)' : {
            fontSize: '15px',
        },
        '@media (min-width: 1100px)' : {
            fontSize: '12px',
        },
        '@media (min-width: 1200px)' : {
            fontSize: '14px',
        },
    },

    featureIcon: {
        margin: 'auto 10px',
        color: '#35c79f',
    },

    frequentlyAskedQuestions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px auto',
    },

    accordions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    accordion: {
        margin: '10px',
        width: '90%',
        padding: '20px',
        border: '2px solid #eee',
    },

    customCreditsPage: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(180, 179, 179, .6)',
        position: 'fixed',
        top: '0',
        left: '0',
    },

    customCreditsCont: {
        width: '500px',
        height: '500px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '50px',
        position: 'relative',
    },

    closeCustomCreditsIcon: {
        fontSize: '30px',
        position: 'absolute',
        top: '20px',
        right: '20px'
    },

    customPriceText1: {
        margin: '20px',
    },

    customPriceText2: {
        margin: '10px auto -20px auto',
    },

    customPriceText3: {
        margin: '10px 5px',
    },

    customCreditInput: {
        margin: '20px',
        padding: '15px',
    },

    textf: {
        textAlign: 'center',
    },
    paymentCurrencies: {
        width: '200px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        // borderTop: '1px solid #777',
        // borderBottom: '1px solid #777',
    },
    currenciesContainer: {
        width: '50%',
        position: 'relative',
    },
    currency: {
        width: '100%',
        border: 'none',
        outline: 'none',
        background: 'none',
        padding: '10px',
        borderRadius: '20px',
    },
    currencyBorder: {
        width: '100%',
        height: '100%',
        backgroundColor: '#1177BB',
        position: 'absolute',
        top: '0',
        left: '0',
        borderRadius: '20px',
    },
})

let data

const PurchaseCredits = () => {
    const navigate = useNavigate()
    const classes = useStyles()
    const [error, setError] = useState('')
    const [customCredts, setCustomCredts] = useState(0)
    const [customAmount, setCustomAmount] = useState(0)
    const [customCredits, setCustomCredits] = useState(false)
    const [currency, setCurrency] = useState('USD')
    const [amount1, setAmount1] = useState(3.49)
    const [amount2, setAmount2] = useState(5.49)
    const [amount3, setAmount3] = useState(9.49)
    const [exchange, setExchange] = useState(1000)

    const creditExchange = 0.001861

    //this function popup the add custom credits page
    const toCustomCredits = (() => {
        setCustomCredits(true)
    })

    //this function closes the add custom credits page
    const closeCustomCreditsPage = (() => {
        setCustomCredits(false)
        setError('')
        setCustomAmount(0)
    })

    //this is the add credits function
    const toAddCredits = ((data) => {
        if (data.amount < 3) {
            setError('Minimum amount is $3')
        } else {
            navigate('/pay-now',  {state: {data: data}}) //this pass the number and amount of credits to be added to the payment option page
            //console.log(data)
        }
    })

    const handleChangeAmount = ((e) => {
        if (e.target.value < 3) {
            setError('Enter minimum of $3')
        } else {
            setError('')
            setCustomAmount(e.target.value)
        }   
    })

    const Error = (() => { // Eroor Component
        if(error !== '') {
          return(
            <Alert severity="error">{error}</Alert>
          )
        }
       })


  return (
    <>
    <div className={classes.addCreditsPage}>
        <h4 className={classes.purchaseHeading}>Purchase Points</h4>
        <h6 className={classes.purchaseHeading2}>Top Up Your Balance and Make Your Ideas Come True with Retexter</h6>
        <div className={classes.currencyPlans}>
            <div className={classes.currencyPlan}>
            <h3 className={classes.credits}>Silver</h3>
                <p className={classes.creditText}>1870 Credits</p>
                <h3 className={classes.amount}>${amount1}</h3>
                <Divider className={classes.divider}/>
                <div className={classes.planFeatures}>
                    {
                        plan350.map((plan) => {
                            return(
                                <h6 className={classes.feature} key={plan}><CheckIcon className={classes.featureIcon}/>{plan}</h6>
                            )
                        })
                    }
                </div>
                <Button className={classes.creditButton} onClick={(() => {toAddCredits(data = {amount: (amount1 * exchange), credits: 1870, currency: "USD"})})}>Add 1870 Credits</Button>
            </div>
            <div className={classes.currencyPlan}>
            <h3 className={classes.credits}>Gold</h3>
                <p className={classes.creditText}>2950 Credits</p>
                <h3 className={classes.amount}>${amount2}</h3>
                <Divider className={classes.divider}/>
                <div className={classes.planFeatures}>
                    {
                        plan500.map((plan) => {
                            return(
                                <h6 className={classes.feature} key={plan}><CheckIcon className={classes.featureIcon}/>{plan}</h6>
                            )
                        })
                    }
                </div>
                <Button className={classes.creditButton} onClick={(() => {toAddCredits(data = {amount: (amount2 * exchange), credits: 2950, currency: "USD"})})}>Add 2950 Credits</Button>
            </div>
            

            <div className={classes.currencyPlan}>
                <h3 className={classes.credits}>Diamond</h3>
                <p className={classes.creditText}>5100 Credits</p>
                <h3 className={classes.amount}>${amount3}</h3>
                <Divider className={classes.divider}/>
                <div className={classes.planFeatures}>
                    {
                        plan1200.map((plan) => {
                            return(
                                <h6 className={classes.feature} key={plan}><CheckIcon className={classes.featureIcon}/>{plan}</h6>
                            )
                        })
                    }
                </div>
                <Button className={classes.creditButton} onClick={(() => {toAddCredits(data = {amount: (amount3 * exchange), credits: 5100, currency: "USD"})})}>Add 5100 Credits</Button>
            </div>
            <div className={classes.currencyPlan}>
                <h3 className={classes.credits}>Custom</h3>
                <p className={classes.creditText}>Credits</p>
                <h3 className={classes.customAmount}>Self-Input Purchase</h3>
                <Divider className={classes.divider}/>
                <div className={classes.planFeatures}>
                    {
                        customPlan.map((plan) => {
                            return(
                                <h6 className={classes.feature} key={plan}><CheckIcon className={classes.featureIcon}/>{plan}</h6>
                            )
                        })
                    }
                </div>
                <Button className={classes.customCreditButton} onClick={(() => {toCustomCredits(data = {currency: "NGN", exchange: exchange})})}>Continue</Button>

            </div>
        </div>

        

        <div className={classes.frequentlyAskedQuestions}>
            <h3 className={classes.textf}>frequently Asked Questions</h3>
            <h5 className={classes.textf}>Find Answers to frequently asked questions about our services</h5>
            <div className={classes.accordions}>
                {questions.map((question) => {
                    return(
                        <Accordion className={classes.accordion} key={question.id}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <Typography className={classes.heading}>{question.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Typography>{question.answer}</Typography>
                        </AccordionDetails>
                    </Accordion> 
                    )
                })}
            </div>
        </div>
    </div>
    {customCredits &&
        <div className={classes.customCreditsPage}>
            <div className={classes.customCreditsCont}>
                <CloseIcon className={classes.closeCustomCreditsIcon} onClick={closeCustomCreditsPage}/>
                <h4 className={classes.customPriceText1}>Custom Price</h4>
                <TextField
                    id="outlined-number"
                    label={`input amount in USD`}
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{max: 10000, min: 100}}
                    variant="outlined"
                    className={classes.customCreditInput}
                    onChange={handleChangeAmount}
                />
                <Error />
                <h4 className={classes.customPriceText3}>{parseFloat((customAmount/creditExchange).toFixed(2))} Credits</h4>
                <Button className={classes.creditButton} onClick={(() => {toAddCredits(data = {amount: (customAmount * 1000), credits: (customAmount/creditExchange).toFixed(2), currency: "NGN"})})}>Add Credits</Button>
            </div>
        </div>
    }
    </>
  )
}

export default PurchaseCredits