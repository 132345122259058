import { onAuthStateChanged } from 'firebase/auth';
import { onValue, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout';
import ProtectedRoute from './Components/PrivateRoute';
import { auth, db } from './Config/FirebaseConfig';
import { AuthProvider } from './Contexts/AuthContext';
import Login from './Pages/LoginPage';
import ResetPassword from './Pages/PasswordReset';
import SignUp from './Pages/SignUpPage';
import VerifyEmail from './Pages/VerifyEmail';
import WelcomePage from './Pages/WelcomePage';

function App() {
  const [currentUser, setCurrentUser] = useState()
  const [timeActive, setTimeActive] = useState(false)
  const [users, setUsers] = useState([])
  const [keys, setKeys] = useState([])
  const [openAiKey, setOpenAiKey] = useState('')
  // const [creditsUsed, setCreditsUsed] = useState(0)

  //const keys = []

  useEffect(() => { //set current user on auth changed
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
     })
  }, [])

  useEffect(() => { //get users from database
    onValue(ref(db, 'Users'), (snapshot) => {
      setUsers([])
      const data = snapshot.val();
      if(data !== null) {
        Object.values(data).map((user) => {
          setUsers((oldArray) => [...oldArray, user])
        })
      }
    })
  }, []);

  useEffect(() => { //get Keys from database
    onValue(ref(db, 'Keys'), (snapshot) => {
      //setKeys([])
      const data = snapshot.val()
      //console.log(data);
      if(data !== null) {
        Object.values(data).map((key) => {
          setKeys((oldArray) => [...oldArray, key])
        })
      }
    })
  }, []);

  //console.log(keys);

  // useEffect(() => {
  //   const openAiKeyRef = keys.find((key) => key.NAME === 'openai')
  //   console.log(openAiKeyRef);
  //   //setOpenAiKey(openAiKeyRef.KEY)
  // }, [])

  // console.log(openAiKey);
  
  return (
    <div className="App">
      <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
        <Routes>
          <Route exact path="/" element={<WelcomePage />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/verifyemail" element={<VerifyEmail />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          <Route element={<ProtectedRoute user={currentUser} />}>
           <Route exact path="*" element={<Layout users={users} keys={keys} openAiKey={openAiKey}/>} />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
