import { Alert } from '@mui/material'
import React, { useState } from 'react'
import OpenAI from "openai";
import { Button } from '@material-ui/core';

const Tests = ({OPENAI_API_KEY}) => {

    const openai = new OpenAI({apiKey: OPENAI_API_KEY, "dangerouslyAllowBrowser": true})

    const [text, setText] = useState('role of supervision in the development of primary education')
    const [result, setResult] = useState('')
    const [error, setError] = useState('')

    const prompt =  `as a project writer for undergraduate students, write comprehensive outline of a research project with the topic: ${text}`

    async function rephrase () {
        if (text !== '') {
            setResult('')
            setError('')
            const completion = await openai.chat.completions.create({
                model: "gpt-4-1106-preview",
                messages: [{ role: "user", content: `rephrase ${text}` }],
                max_tokens: 2500,
                temperature: 0.5,
            });
            console.log(completion);
            setResult(completion.choices[0].message.content)
        } else {
            setError('input your text')
        }
    }

    const Error = (() => { // Eroor Component
        if(error !== '') {
          return(
            <Alert severity="error">{error}</Alert>
          )
        }
       })
  return (
    <div>
      <Error />
      <div>{prompt}</div>
      <div>{result}</div>
      <Button onClick={rephrase}>Submit</Button>
    </div>

  )
}

export default Tests