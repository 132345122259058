// Highlighter.js

import React from 'react';
import { diff_match_patch } from 'diff-match-patch';
import '../Assets/Styles/Highlighter.css'

const dmp = new diff_match_patch();

const Highlighter = ({ text, plagiarizedText }) => {
  const diffs = dmp.diff_main(plagiarizedText, text);
  dmp.diff_cleanupSemantic(diffs);

  const highlightedText = diffs.map((diff, index) => {
    const className = diff[0] === 0 ? 'change' : 'no-change';
    return (
      <span key={index} className={className}>
        {diff[1]}
      </span>
    );
  });

  return <div>{highlightedText}</div>;
};

export default Highlighter;
