import React from 'react'
import {useState, useEffect} from 'react'
import {sendEmailVerification} from 'firebase/auth'
import { useNavigate} from 'react-router-dom'
import { useAuthValue } from '../Contexts/AuthContext'
import { auth } from '../Config/FirebaseConfig'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@mui/material'
import logo from '../Assets/logo.png'
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1177BB',
    backgroundColor: '#d6dddd',
    minHeight: '100vh',
},
infoCont: {
  width: '85%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '10px 0 20px 0',
  '@media (max-width: 400px)': {
    width: '95%',
},

},
header: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
headerLogo: {
  width: '50px',
  height: "50px",
  background: 'none',
  margin: '10px 5px',
},
headerText: {
  fontSize: '25px',
  fontWeight: '700',
  margin: '10px 0',
  color: '#1177BB',
},
btn: {
  width: '150px',
  margin: '5px',
  padding: '10px',
  fontSize: '15px',
  borderRadius: '5px',
  backgroundColor: '#1177BB',
  color: '#ffffff',
  border: 'none',
  outline: 'none',
  "&:hover": {
    backgroundColor: "#003399"
     }
},
verify: {
  fontSize: '20px',
  fontWeight: '600',
  textAlign: 'center',
  margin: '10px',
},
description: {
  fontSize: '17px',
  fontWeight: '300',
  textAlign: 'center',
  margin: '10px',
},
instruction: {
  fontSize: '20px',
  fontWeight: '600',
  textAlign: 'center',
  margin: '10px',
},
})

const VerifyEmail = () => {
  const classes = useStyles()
    const {timeActive, setTimeActive} = useAuthValue()

    const {currentUser} = useAuthValue()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [time, setTime] = useState(60)
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const resendEmailVerification = () => { // Resend email verification link
        setButtonDisabled(true)
        sendEmailVerification(auth.currentUser)
        .then(() => {
          setError('')
          setButtonDisabled(false)
          setTimeActive(true)
        }).catch((err) => {
          setError(err.message);
          setButtonDisabled(false)
        })
      }

      useEffect(() => { // Check if email verified and redirect the user to login page
        const interval = setInterval(() => {
          currentUser?.reload()
          .then(() => {
            if(currentUser?.emailVerified){
              clearInterval(interval)
              navigate('/verifyemail')
            }
            if(currentUser.emailVerified){
              //set verified here
              navigate('/')
            }
          })
          .catch((err) => {
            if(err.message === 'Firebase: Error (auth/network-request-failed)'){
              setError('Network request Failed, Check your connection')
            } else {
              setError('Something went wrong, make sure you have network connection and your details are correct, then try again')
            }
          })
        }, 1000)
      }, [navigate, currentUser])

      useEffect(() => { // Set the time for requesting resend link
        let interval = null
        if(timeActive && time !== 0 ){
          interval = setInterval(() => {
            setTime((time) => time - 1)
          }, 1000)
        }else if(time === 0){
          setTimeActive(false)
          setTime(60)
          clearInterval(interval)
        }
        return () => clearInterval(interval);
      }, [timeActive, time])

    const Error = (() => {
      if(error !== '') {
        return(
          <Alert severity="error">{error}</Alert>
        )
      }
      })
    return (
        <div className={classes.container}>
          <div className={classes.infoCont}>
            <div className={classes.header}>
              <img src={logo} alt="Logo" className={classes.headerLogo}/>
              <h3 className={classes.headerText}>Retexter</h3>
            </div>
            <h3 className={classes.verify}>Verify your email adress</h3>
            <Error />
            <h3 className={classes.description}>A verification link has been sent to:<span>{auth.currentUser?.email}</span></h3>
            <h5 className={classes.instruction}>Follow the instructions in the email to verify your account. If you cant find the email, Check SPAM folder</h5>
            <button
              className={classes.btn}
              onClick={resendEmailVerification}
              disabled={timeActive}
            >Resend Email {timeActive && time}
            </button>
            <MailOutlineIcon style={{fontSize: '50px', fontWeight: '200'}}/>
          </div>
        </div>
    )
}

export default VerifyEmail
