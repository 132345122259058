import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyD_fQaueEy57FfmG7F5OnnjBJ17uiGJk2A",
    authDomain: "retexter-ai.firebaseapp.com",
    projectId: "retexter-ai",
    storageBucket: "retexter-ai.appspot.com",
    messagingSenderId: "352047277232",
    appId: "1:352047277232:web:43e341515320f0ddf30775",
    measurementId: "G-ZT76X3BV3T"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)
export const auth = getAuth(app)
const analytics = getAnalytics(app);