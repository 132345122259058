import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import background from '../Assets/background.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { Alert } from '@mui/material'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3'
import { usePaystackPayment } from 'react-paystack'
import {useMonnifyPayment} from 'react-monnify';
import { ref, set, update } from 'firebase/database';
import { auth, db } from '../Config/FirebaseConfig';

const useStyles = makeStyles({
  purchaseCreditsPage: {
    width: '100%',
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '@media (max-width: 800px)' : {
      marginTop: '30px',
  },
  },
  paymentOption: {
    width: '40%',
    height: '70px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #ccc',
    boxShadow: '2px 3px #ccc',
    margin: '20px',
    padding: '10px',
    "&:hover": {
      width: '42%',
      height: '75px',
    },
    '@media (max-width: 800px)' : {
      width: '80%',
      "&:hover": {
        width: '82%',
        height: '75px',
      },
  },
  optionText: {
    margin: '20px',
  },

  paystackButton:{
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: '1px solid #ccc',
    boxShadow: '2px 3px #ccc',
    textAlign: 'center',
    background: '#00000000',
    "&:hover": {
      width: '100%',
      height: '75px',
    }
  },
    
}})

const PurchaseCreditsPage = ({users, FLUTTERWAVE_API_KEY, MONIFY_API_KEY, PAYSTACK_API_KEY }) => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const [error, setError] = useState('')
  const [paymentData, setPaymentData] = useState({}) 
  const [transactionData, setTransactionData] = useState({})

  const logedUser = users?.find((user) => user.userId === auth.currentUser?.uid )
  const currentBalance = logedUser?.balance


  useEffect(() => {
    if(location.state !== null) {
      setPaymentData(location?.state?.data)
    }
  }, []);

  //flutterwave configuration
  const flutterWaveConfig = { 
    public_key: FLUTTERWAVE_API_KEY,
    tx_ref: Date.now(),
    amount: paymentData.amount,
    currency: "NGN",
    payment_options: 'card,mobilemoney,ussd',
    customer: {
        email: logedUser?.email,
        phone_number: '',
        name: logedUser?.userName,
    },
    customizations: {
        title: 'Add Credits',
        description: 'Add Credits',
        logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
};

//flutterwave initialization
const handleFlutterPayment = useFlutterwave(flutterWaveConfig);


//Monify Configuration
const monifyConfig = {
  amount: 5000,
  currency: "USD",
  reference: '' + Math.floor(Math.random() * 1000000000 + 1),
  customerFullName: logedUser?.userName,
  customerEmail: logedUser?.email,
  customerMobileNumber: '',
  apiKey: MONIFY_API_KEY,
  contractCode: '4934121693',
  paymentDescription: 'Add Credits',
  isTestMode: true,
  metadata: {
    name: logedUser?.userName,
    //age: 23,
  },
};

const componentPropsMonify = {
  ...monifyConfig,
  onSuccess: response => console.log(response),
  onClose: response => console.log(response),
};
const initializePayment = useMonnifyPayment(componentPropsMonify);

//flutterwave payment function
  const flutterwavePayment = (() => {
    setError('')
    const paymentFunction = (() => {
      //console.log(paymentData, transactionData);
      setError('')
       handleFlutterPayment({
           callback: (response) => {
               //console.log(response);
               var data = response
               setTransactionData(data)
                update(ref(db, `Users/${auth.currentUser.uid}`), {
                  balance: (currentBalance + parseInt(paymentData.credits)),
                  })
                set(ref(db, `Transactions/${transactionData.tx_ref}`), { // firebase function to save the transaction to the database
                  transactionReference: transactionData.tx_ref,
                  status: transactionData.status,
                  transactionTime:  (new Date()).getTime(),
                })
           },
           onClose: () => {alert('Transaction Cancelled')},
      });
      //console.log(paymentData, transactionData);
    })
    if(paymentData.amount !== 0 ) {
      setError('')
      if (paymentData.amount >= 3) {
        paymentFunction()
      } else {
        setError('Minimum Amount to Add is $3')
      }
    }else{
        setError('Amount to Add Must Not Be Zero')
    }}
  )

  //PAYSTACK CONFIG
  const paystackConfig = {
    reference: (new Date()).getTime().toString(),
    email: logedUser?.email,
    amount: paymentData.amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    currency: "NGN",
    publicKey: PAYSTACK_API_KEY,
  };

const onSuccess = (reference) => {
  // Implementation for whatever you want to do with reference and after success call.
  //console.log(reference);
  update(ref(db, `Users/${auth.currentUser.uid}`), {
    balance: (currentBalance + parseInt(paymentData.credits)),
    })
  set(ref(db, `Transactions/${reference.reference}`), { // firebase function to save the transaction to the database
    email: logedUser?.email,
    name: logedUser?.userName,
    oldBalance: logedUser?.balance,
    newBalance: logedUser?.balance + parseInt(paymentData.credits),
    transactionAmount: reference.amount,
    transactionReference: reference.reference,
    status: reference.message,
    transactionTime:  (new Date()).getTime(),
  })
  closePaymentModal() // this will close the modal programmatically
  navigate('/pharaphraser')
};

const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  alert('Transaction Cancelled')
  navigate('/pay-now')
}

  const initializePaystackPayment = usePaystackPayment(paystackConfig);

  //Monify payment function
  const monifyPayment = (() => {
    //console.log('pay with monify', paymentData);
  })
//console.log(paystackConfig);
  const Error = (() => {
    if(error !== '') {
      return(
        <Alert severity="error">{error}</Alert>
      )
    }
   })

  return (
    <div className={classes.purchaseCreditsPage}>
      <h4>Choose Payment Option</h4>
      <Error />
      <div className={classes.paymentOption} onClick={flutterwavePayment}>
        <h6 className={classes.optionText}>Pay with FlutterWave</h6>
      </div>
      <div className={classes.paymentOption} onClick={() => {initializePaystackPayment(onSuccess, onClose)}}>
        <h6 className={classes.optionText}>Pay with Paystack</h6>
      </div>
      <div className={classes.paymentOption} onClick={monifyPayment} style={{display: 'none'}}>
        <h6 className={classes.optionText}>Pay With Monify</h6>
      </div>
    </div>
  )
}

export default PurchaseCreditsPage