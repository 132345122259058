import React, {useState} from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { auth } from '../Config/FirebaseConfig';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles ({
    profilePage: {
        width: '100%',
        minHeight: '66vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 800px)' : {
            flexDirection: 'column',
        },
    },

    details: {
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto 50px',
        '@media (max-width: 800px)' : {
            width: '80%',
        },
    },

    avatar: {
        width: '100px',
        height: '100px',
        backgroundColor: '#1177BB',
        color: '#fff',
        fontSize: '50px',
        margin: '30px'
    },

    profileName: {
        margin: '10px',
        fontSize: '20px',
        fontWeight: '500',
    },

    profileEmail: {
        margin: '5px',
        fontSize: '15px',
        fontWeight: '300',
    },

    info: {
        width: '65%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-starts',
        '@media (max-width: 800px)' : {
            width: '95%',
        },
    },

    infoHeading: {
        display: 'flex',
    },

    profileLink: {
        margin: '10px 20px',
        border: 'none',
        outline: 'none',
        pointer: 'cursor',
        backgroundColor: '#fff',
    },

    balanceLink: {
        margin: '10px 20px',
        border: 'none',
        outline: 'none',
        pointer: 'cursor',
        backgroundColor: '#fff',
        display: 'none',
    },

    infoDetails: {
        width: '100%',
        backgroundColor: '#hhh',
        display: 'flex',
        boxShadow: '2px 3px #ccc',
        border: '1px solid #ccc',
        '@media (max-width: 800px)' : {
            flexDirection: 'column',
        },
    },

    userInfo: {
        width: '60%',
        margin: '20px',
    },

    infoDetailsH6: {
        margin: '20px 10px',
        fontSize: '15px'
    },

    infoDetailsP: {
        margin: '10px 13px',
        fontSize: '20px'
    },

    userAction: {
        width: '30%',
        margin: '20px',
    },

    userActionH6: {
        margin: '20px 10px',
        fontSize: '18px'
    },

    action: {
        display: 'flex',
        border: 'none',
        outline: 'none',
        pointer: 'cursor',
        background: 'none',
    },

    actionIcon: {
        fontSize: '25px',
        color: '#1177BB',
        margin: '10px'
    },

    actionTitle: {
        margin: '10px',
        fontSize: '15px',
        color: '#1177BB',
        '@media (max-width: 800px)' : {
            width: '150px',
            margin: '10px 10px 10px 0',
        },
    },
})

const ProfilePage = ({users}) => {
    const classes = useStyles()
    const logedUser = users?.find((user) => user.userId === auth.currentUser?.uid)

    const name = logedUser?.userName
    const email = logedUser?.email
    const balance = logedUser?.balance

    const [show, setShow] = useState('profile')
    const [error, setError] = useState('')
    const navigate = useNavigate()

    const showProfile = (() => {
        setShow('profile')
    })

    const showBalance =(() => {
        setShow('balance')
    })

    const changePassword = (() => {
        //console.log('you want to chage your password?');
    })

    const logout = (() => {
        signOut(auth)// firebase function to sign out user
        .then(() => { //what happen after the user signed out
          sessionStorage.removeItem('isAuth')
          navigate('/login')
        })
        .catch(err => setError('something went wrong, retry'))
    })

    const Error = (() => { // Error Component
        if(error !== '') {
          return(
            <Alert severity="error">{error}</Alert>
          )
        }
       })

  return (
    <div className={classes.profilePage}>
        <div className={classes.details}>
            <Avatar className={classes.avatar}>{logedUser?.userName[0]}</Avatar>
            <h3 className={classes.profileName}>{name}</h3>
            <h4 className={classes.profileEmail}>{email}</h4>
        </div>
        <div className={classes.info}>
            <div className={classes.infoHeading}>
                <button className={classes.profileLink} onClick={showProfile}>Profile</button>
                <button className={classes.balanceLink} onClick={showBalance}>Balance</button>
            </div>
            <div className={classes.infoDetails}>
                {show === 'profile' ? <>
                <div className={classes.userInfo}>
                    <h6 className={classes.infoDetailsH6}>Name</h6>
                    <p className={classes.infoDetailsP}>{name}</p>
                    <h6 className={classes.infoDetailsH6}>Email</h6>
                    <p className={classes.infoDetailsP}>{email}</p>
                    <h6 className={classes.infoDetailsH6}>Balance</h6>
                    <p className={classes.infoDetailsP}>{balance} Credits</p>
                </div>
                <div className={classes.userAction}>
                    <h6 className={classes.userActionH6}>Account</h6>
                    <Error />
                    <button className={classes.action} onClick={changePassword}>
                        <AutorenewIcon className={classes.actionIcon}/>
                        <h6 className={classes.actionTitle}>Change Password</h6>
                    </button>
                    <button className={classes.action} onClick={logout}>
                        <ExitToAppIcon className={classes.actionIcon}/>
                        <h6 className={classes.actionTitle}>Sign Out</h6>
                    </button>
                </div></> : <div className={classes.userInfo}>
                    <h6 className={classes.infoDetailsH6}>Balance</h6>
                    <p className={classes.infoDetailsP}>{balance} Credits</p>
                </div>
                }
            </div>
        </div>
    </div>
  )
}

export default ProfilePage